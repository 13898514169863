import styled from '@emotion/styled';
import { PrimaryButton } from './Button';
import { Container, GridItem } from './Layout';
import { Text } from 'components/Typography';
import { Header } from './PageLayout';

export const MainStatusContainer = styled(Container)`
  padding-top: 36px;
  padding-left: 150px;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-left: 0;
    padding-top: 0;
  }
`;

export const InnerStatusContainer = styled(Container)`
  padding: ${({ theme }) => theme.padding.l};
  background: ${({ theme }) => theme.palette.contact.primary};
  border-radius: ${({ theme }) => theme.border.radius};
  margin-bottom: 12px;
`;

export const ActionButton = styled(PrimaryButton)`
  padding: 0;
  background: transparent;
  line-height: 17px;
  font-size: ${({ theme }) => theme.fontSize.m};
  border: 0;
  color: ${({ theme }) => theme.palette.primary.main};
  :hover {
    background: unset;
    color: ${({ theme }) => theme.palette.primary.activeText};
    p {
      color: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
  text-transform: none;
  &:disabled {
    background: transparent;
    pointer-events: unset;
    cursor: not-allowed;
    p {
      color: ${({ theme }) => theme.palette.primary.disabled};
    }
  }
`;

export const ActionText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.m};
  svg {
    margin-right: 15px;
  }
  & .image {
    height: 35px;
    width: 35px;
  }
`;

export const ItemWrapper = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

export const StatusHeader = styled(Header)`
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

export const Line = styled.div`
  margin: 20px 0;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.active}`};
`;
