import styled from '@emotion/styled';

interface Props {
  number: string | null;
}

export function RegistrationNo({ number }: Props) {
  return <Box>{number}</Box>;
}

const Box = styled.div`
  border: ${({ theme }) => `1px solid ${theme.palette.background.dark}`};
  border-radius: ${({ theme }) => theme.border.radius};
  font-weight: bold;
  padding: ${({ theme }) => theme.padding.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 68px;
`;
