import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { ROUTES } from './routes';
import { useUser } from 'pages/hooks';
import { Permit } from 'api/resources/models/AutoGenerated';

interface Props {
  component?: React.FC<RouteComponentProps>;
  render?: (props: RouteComponentProps) => React.ReactNode;
  path: string;
  exact?: boolean;
  anyOfPermits?: Permit[];
}

export default function RouteWithPermit(props: Props) {
  const { user, isFetching } = useUser();

  const permitAllowed = user?.userPermits.some((userPermit) =>
    props.anyOfPermits?.some((permit) => permit === userPermit.code)
  );

  if (isFetching) return null;

  return permitAllowed ? (
    <Route
      path={props.path}
      exact={props.exact}
      component={props.component}
      render={props.render}
    />
  ) : (
    <Redirect to={ROUTES.dashboard} />
  );
}
