import { DatePickerField } from 'components/DatePickerFields';
import NumberField from 'components/NumberField';
import {
  InputContainer,
  InputFirst,
  Header,
  InputSecond,
  Label,
  MainInputContainer,
} from 'components/PageLayout';
import { Controller, useFormContext } from 'react-hook-form';
import { RepairManualUpdate } from './form';

export default function Invoice() {
  const { control } = useFormContext<RepairManualUpdate>();

  return (
    <MainInputContainer>
      <InputContainer direction="row">
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Owner Excess (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.ownerExcess"
            control={control}
          />
        </InputFirst>
      </InputContainer>
      <Header>Line Items</Header>
      <InputContainer direction="row">
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Labour Hour (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.labourHours"
            control={control}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>Labour Value (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.invoicedLabour"
            control={control}
          />
        </InputSecond>
      </InputContainer>
      <InputContainer>
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Parts Value (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.invoicedParts"
            control={control}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>Paint Value (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.invoicedPaint"
            control={control}
          />
        </InputSecond>
      </InputContainer>
      <InputContainer>
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Other Value (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.invoicedOther"
            control={control}
          />
        </InputFirst>
      </InputContainer>
      <Header>Invoice Details</Header>
      <InputContainer>
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Tax (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.invoicedTax"
            control={control}
          />
        </InputFirst>
        <InputSecond md={6} sm={6} xs={12}>
          <Label>Total (numbers only)</Label>
          <Controller
            render={({ field, fieldState }) => (
              <NumberField {...field} fieldState={fieldState} />
            )}
            name="invoice.invoicedTotal"
            control={control}
          />
        </InputSecond>
      </InputContainer>
      <InputContainer>
        <InputFirst md={6} sm={6} xs={12}>
          <Label>Invoice Date</Label>
          <Controller
            render={({ field }) => <DatePickerField {...field} />}
            name="invoice.firstInvoiceDate"
            control={control}
          />
        </InputFirst>
      </InputContainer>
    </MainInputContainer>
  );
}
