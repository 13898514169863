import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Text } from '../components/Typography';
import styled from '@emotion/styled';
import { AccordionItem } from './Menu/types';
import PrimaryAccordionDetails from './PrimaryAccordionDetails';

export type AccordionTypes = 'panel1' | 'panel2' | 'panel3' | 'panel4' | false;

interface Props {
  accordionItems: AccordionItem[];
}

export default function PrimaryAccordion({ accordionItems }: Props) {
  const [expanded, setExpanded] = useState<AccordionTypes>(false);
  return (
    <>
      {accordionItems.map((item, index) => {
        return (
          <StyledAccordion
            key={index}
            expanded={expanded === item.id}
            onChange={(_event: ChangeEvent<unknown>, isExpanded: boolean) =>
              setExpanded(isExpanded ? item.id : false)
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Text>{item.summary}</Text>
            </AccordionSummary>
            <AccordionDetails>
              <PrimaryAccordionDetails details={item.details} />
            </AccordionDetails>
          </StyledAccordion>
        );
      })}
    </>
  );
}

const StyledAccordion = styled(Accordion)`
  & .MuiGrid-container {
    flex-wrap: unset;
  }
  & .icon {
    align-items: center;
    margin-bottom: ${({ theme }) => theme.margin.s};
    svg {
      margin-right: ${({ theme }) => theme.margin.xs};
    }
  }
`;
