import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as FilterIcon } from 'assets/ActivitiesFilter.svg';
import { Container, GridItem } from 'components/Layout';
import { Subheading, Text } from 'components/Typography';
import { SecondaryButton } from 'components/Button';
import { ActivityItem } from './ActivityItem';
import { Activity } from '../helpers';
import Permits from 'components/Permits';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Checkbox } from 'components/Checkbox';
import uniqBy from 'lodash/uniqBy';
import { BottomShadow, TopShadow } from '../Shadow';
import { Permit } from 'api/resources/models/AutoGenerated';

interface Props {
  data: Activity[];
  onAddClick: () => void;
}

export function ActivityList({ data, onAddClick }: Props) {
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(true);
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(false);
  const [isActivitiesFilterOpen, setIsActivitiesFilterOpen] = useState(false);
  const [filterItems, setFilterItems] = useState([
    { selected: false, label: 'Select All' },
    ...uniqBy(data, (item) => item.category).map((item) => {
      return { selected: false, label: item.category };
    }),
  ]);

  const selectedFilterItems = filterItems
    .filter((item) => item.selected)
    .map((item) => item.label);

  const joinedSelectedFilterItems = selectedFilterItems.join(', ');
  const filterButtonLabel =
    selectedFilterItems.length === filterItems.length
      ? 'All'
      : joinedSelectedFilterItems === ''
        ? 'Filter'
        : joinedSelectedFilterItems;

  const activities =
    selectedFilterItems.length === 0
      ? data
      : data.filter((activity) =>
        selectedFilterItems.includes(activity.category)
      );

  return (
    <MainContainer direction="column">
      <GridItem>
        <Title>Activities</Title>
      </GridItem>
      <ButtonsContainer>
        <GridItem xs={6}>
          <Container justify="flex-start">
            <FilterButton
              hasItems={selectedFilterItems.length > 0}
              onClick={() => setIsActivitiesFilterOpen(!isActivitiesFilterOpen)}
            >
              <FilterIcon />
              <StyledText noWrap>{filterButtonLabel}</StyledText>
            </FilterButton>
          </Container>
          {isActivitiesFilterOpen && (
            <ClickAwayListener
              onClickAway={() => setIsActivitiesFilterOpen(false)}
            >
              <FilterItemsContainer>
                <Container direction="column">
                  {filterItems.map(
                    (filterItem, index) =>
                      filterItem.label && (
                        <GridItem key={index}>
                          <FilterItem
                            label={filterItem.label}
                            labelWeight={index === 0 ? 'bold' : 'regular'}
                            index={index}
                            checked={filterItem.selected}
                            onChange={(event) =>
                              handleChange(event.target.checked, index)
                            }
                          />
                        </GridItem>
                      )
                  )}
                </Container>
              </FilterItemsContainer>
            </ClickAwayListener>
          )}
        </GridItem>
        <Permits permit={Permit.ADD_ACTIVITY}>
          <GridItem xs={6}>
            <Container justify="flex-end">
              <StyledSecondaryButton onClick={onAddClick}>
                <AddIcon />
                <StyledText noWrap>Add Activity</StyledText>
              </StyledSecondaryButton>
            </Container>
          </GridItem>
        </Permits>
      </ButtonsContainer>
      <TopShadow isVisible={isTopShadowVisible} />
      <ScrollableContainer
        onScroll={({ isOnTop, isOnBottom }) => {
          setIsBottomShadowVisible(!isOnBottom);
          setIsTopShadowVisible(!isOnTop);
        }}
      >
        {activities.map((activity, i) => {
          return <ActivityItem key={i} item={activity} />;
        })}
      </ScrollableContainer>
      <BottomShadow isVisible={isBottomShadowVisible} />
    </MainContainer>
  );

  function handleChange(isSelected: boolean, index: number) {
    setFilterItems((currentItems) => {
      if (currentItems.length === 0) return currentItems;

      // switching all checkboxes
      if (index === 0)
        return currentItems.map((item) => ({ ...item, selected: isSelected }));

      const newItems = currentItems.map((item, itemIndex) => ({
        ...item,
        selected: itemIndex === index ? isSelected : item.selected,
      }));

      const nonAllItems = newItems.slice(1);
      const selectedLength = nonAllItems.filter((item) => item.selected).length;

      const allAreSelected = selectedLength === nonAllItems.length;
      if (allAreSelected)
        return newItems.map((item) => ({ ...item, selected: true }));

      const allItem = newItems[0];
      return [{ ...allItem, selected: false }, ...nonAllItems];
    });
  }
}

const Title = styled(Subheading)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

const MainContainer = styled(Container)`
  word-break: break-all;
  white-space: pre-wrap;
`;

const ScrollableContainer = styled(GridItem)`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    height: 508px;
    overflow-y: auto;
    padding-right: 6px;
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.secondary.main};
      border-radius: 10px;
      margin-left: 6px;
    }

    scrollbar-color: ${({ theme }) => theme.palette.secondary.main} transparent;
    scrollbar-width: thin;
  }
`;

const ButtonsContainer = styled(Container)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;
const StyledSecondaryButton = styled(SecondaryButton)`
  display: flex;
  height: 40px;
  :hover {
    background-color: ${({ theme }) => theme.palette.primary.hover};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.primary.active};
    border: ${({ theme }) => `2px solid ${theme.palette.primary.activeBorder}`};
    color: ${({ theme }) => theme.palette.primary.activeText};
    p {
      color: ${({ theme }) => theme.palette.primary.activeText};
    }
  }
`;

const FilterButton = styled(StyledSecondaryButton) <{
  hasItems: boolean;
}>`
  background-color: ${({ theme, hasItems }) =>
    hasItems ? theme.palette.primary.active : 'inherits'};
  border: ${({ theme, hasItems }) =>
    `1px solid ${hasItems ? theme.palette.primary.selected : 'inherits'}`};
  path {
    stroke: ${({ theme, hasItems }) =>
    hasItems ? theme.palette.primary.selected : 'inherits'};
  }
  p {
    color: ${({ theme, hasItems }) =>
    hasItems ? theme.palette.primary.selected : 'inherits'};
  }
`;

const StyledText = styled(Text)`
  margin: 12px;
`;

const FilterItemsContainer = styled.div`
  margin-top: 4px;
  position: absolute;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.shadow.m};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 16px 0px 16px 12px;
  min-width: 200px;
`;

const FilterItem = styled(Checkbox) <{ index: number }>`
  color: ${({ theme }) => theme.palette.button.dark};
`;
