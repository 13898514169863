import { AccountProfile } from './helpers';

export interface AccountProfilesTableHeader {
  id: keyof AccountProfile;
  label: string;
}

export const accountProfilesTableHeaders: AccountProfilesTableHeader[] = [
  { id: 'accountProfileId', label: 'ID' },
  { id: 'name', label: 'Account Profile Name' },
  { id: 'companyName', label: 'Company' },
  { id: 'isTemplate', label: 'Template' },
  { id: 'isCreatingAsCopy', label: 'Create as Copy' },
  // { id: 'userCount', label: 'User Count' },
];

export const disabledAccountProfileColumns: (keyof AccountProfile)[] = [
  'isCreatingAsCopy',
];
