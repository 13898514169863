import styled from '@emotion/styled';

export const BottomShadow = styled.div<{ isVisible?: boolean }>`
  pointer-events: none;
  margin-top: -79px;
  height: 80px;
  background: linear-gradient(180deg, rgba(241, 248, 252, 0) 0%, #f1f8fc 100%);
  z-index: 1;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    visibility: hidden;
  }
`;

export const TopShadow = styled.div<{ isVisible?: boolean }>`
  pointer-events: none;
  margin-bottom: -80px;
  height: 80px;
  background: linear-gradient(0deg, rgba(241, 248, 252, 0) 0%, #f1f8fc 100%);
  z-index: 1;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    visibility: hidden;
  }
`;
