/** @jsxImportSource @emotion/react */
import { Container } from 'components/Layout';
import Drawer from '@mui/material/Drawer';
import { topMenuItems } from './types';
import { DesktopMenu } from './DesktopMenu';
import styled from '@emotion/styled';
import { UserPermit } from 'api/resources/models/AutoGenerated';
import { useAppSelector } from 'core/store';

interface Props {
  userPermits?: UserPermit[];
}

export function Menu({ userPermits }: Props) {
  const user = useAppSelector((state) => state.auth.user);
  return user && userPermits ? (
    <MainContainer>
      <StyledDrawer
        variant="permanent"
        classes={{
          paper: 'drawer',
        }}
      >
        {userPermits && (
          <DesktopMenu userPermits={userPermits} topMenuItems={topMenuItems} />
        )}
      </StyledDrawer>
    </MainContainer>
  ) : (
    <div></div>
  );
}

const MainContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: none;
  }
`;

const StyledDrawer = styled(Drawer)`
  width: ${({ theme }) => theme.palette.drawer.width};

  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.background.dark};

    &.drawer {
      width: ${({ theme }) => `${theme.palette.drawer.width}px`};
      overflow-x: hidden;
    }
  }
`;
