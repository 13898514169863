import { DataAccessProfile } from 'api/resources/models/AutoGenerated';

export interface DataAccessProfileTableHeader {
  id: keyof DataAccessProfile;
  label: string;
}

export const dataAccessProfilesTableHeaders: DataAccessProfileTableHeader[] = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'wpIdMask', label: 'Mask' },
  { id: 'companyId', label: 'Company ID' },
  { id: 'companyName', label: 'Company' },
];
