import { Container, GridItem } from 'components/Layout';
import styled from '@emotion/styled';
import { Text } from 'components/Typography';
import { ReactComponent as RightArrow } from 'assets/RightArrow.svg';
import { ListItem } from './types';
import { useHistory } from 'react-router-dom';

interface Props {
  item: ListItem;
}

export function LinkItem({ item }: Props) {
  const { push } = useHistory();
  return (
    <MenuItem
      alignItems="center"
      disabled={item.disabled}
      onClick={() => {
        if (item.disabled) return;
        push(item.link);
      }}
    >
      <GridItem>
        <Text>{item.name}</Text>
      </GridItem>
      <GridItem>
        <Container>
          <RightArrow />
        </Container>
      </GridItem>
    </MenuItem>
  );
}

const MenuItem = styled(Container)<{ disabled?: boolean }>`
  width: 312px;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.padding.m} 0`};

  &:not(:last-child) {
    border-bottom: 1px solid #ddecf4;
  }

  p,
  path {
    color: ${({ theme, disabled }) =>
      disabled && theme.palette.primary.disabled};
    fill: ${({ theme, disabled }) =>
      disabled && theme.palette.primary.disabled};
  }

  &:hover {
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
    p,
    path {
      color: ${({ theme, disabled }) =>
        !disabled && theme.palette.primary.activeText};
      fill: ${({ theme, disabled }) =>
        !disabled && theme.palette.primary.activeText};
    }
  }
`;
