import { Container, GridItem } from 'components/Layout';
import { Subheading } from 'components/Typography';
import { ServiceLevelAgreement } from '../helpers';
import styled from '@emotion/styled';
import { ServiceLegalItem } from './ServiceLegalItem';
import Permits from 'components/Permits';
import { BottomShadow, TopShadow } from '../Shadow';
import { useCallback, useRef, useState } from 'react';
import { Permit } from 'api/resources/models/AutoGenerated';

interface Props {
  data: ServiceLevelAgreement[];
}

export function ServiceLegal({ data }: Props) {
  const [isBottomShadowVisible, setIsBottomShadowVisible] = useState(false);
  const [isTopShadowVisible, setIsTopShadowVisible] = useState(false);
  const slaRef = useRef<HTMLDivElement | null>(null);

  const onSlaToggle = useCallback(
    () =>
      slaRef.current?.clientHeight !== slaRef.current?.scrollHeight
        ? setIsBottomShadowVisible(true)
        : setIsBottomShadowVisible(false),
    [slaRef]
  );

  return (
    <Permits permit={Permit.VIEW_SLA}>
      <Container direction="column" xs={12}>
        <GridItem>
          <Title>SLA’s</Title>
        </GridItem>
        <TopShadow isVisible={isTopShadowVisible} />
        <ScrollableContainer
          onScroll={({ isOnTop, isOnBottom }) => {
            setIsBottomShadowVisible(!isOnBottom);
            setIsTopShadowVisible(!isOnTop);
          }}
          ref={slaRef}
        >
          {data.map((item, i) => (
            <ServiceLegalItem
              onToggle={onSlaToggle}
              key={i}
              item={item}
              initialExpandState={i === 0}
            />
          ))}
        </ScrollableContainer>
        <BottomShadow isVisible={isBottomShadowVisible} />
      </Container>
    </Permits>
  );
}

const Title = styled(Subheading)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

const ScrollableContainer = styled(GridItem)`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    height: 600px;
    overflow-y: auto;
    padding-right: 6px;
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.secondary.main};
      border-radius: 10px;
    }

    scrollbar-color: ${({ theme }) => theme.palette.secondary.main} transparent;
    scrollbar-width: thin;
  }
`;
