import { api } from 'api';
import { config } from 'core/config';
import { ScheduleConfig } from './models/AutoGenerated';

const maintenanceBaseUrl = '/api/maintenanceMessages';

export const getMaintenanceConfig = (): Promise<ScheduleConfig> =>
  api
    .get(`${config.activewebApiUrl}${maintenanceBaseUrl}`)
    .then((response) => response.data);
