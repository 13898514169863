import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import { DateStatus } from './types';
import { IconContainer } from './IconContainer';
import styled from '@emotion/styled';

interface Props {
  status: DateStatus;
  className?: string;
}

export function DateStatusIcon({
  status,
  className,
}: Props): JSX.Element | null {
  let icon: JSX.Element | null = null;

  if (status === 'green') icon = <CheckCircleIcon />;
  if (status === 'amber') icon = <ErrorIcon />;
  if (status === 'red') icon = <CancelIcon />;

  return icon ? (
    <IconContainer className={className} status={status}>
      {icon}
    </IconContainer>
  ) : <div className={className} style={{ width: '25px' }}></div>;
}

export const GridDateStatusIcon = styled(DateStatusIcon)`
  padding-right: 6px;
  width: 25px;
`;
