import styled from '@emotion/styled';
import darkLogo from 'assets/darkLogo.png';
import { Container, GridItem } from './Layout';
import { Loader } from './Loader';
import { Text } from './Typography';

export function LoadingScreen() {
  return (
    <MainContainer alignItems="center" direction="column" justify="center">
      <GridItem>
        <LogoImg src={darkLogo} />
      </GridItem>
      <Text fontSize="m">Loading. This will take a few moments.</Text>
      <Spinner />
    </MainContainer>
  );
}

const MainContainer = styled(Container)`
  min-height: 100vh;
`;

const LogoImg = styled.img`
  width: 140px;
  height: 20px;
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const Spinner = styled(Loader)`
  margin-top: 36px;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.palette.button.dark};
  }
`;
