import { AccountProfile, SortOrder } from 'api/resources/models/AutoGenerated';
import { isSortOrder } from 'utils/helpers';

export type AccountProfilesParams = {
  page: number;
  companyId: number | undefined;
  isTemplate: boolean | undefined;
  sortString: string | undefined;
  sortOrder: SortOrder | undefined;
};

export function useAccountProfilesParams(
  url: string
): AccountProfilesParams | string {
  const urlParams = new URLSearchParams(url);
  const page = urlParams.get('page');
  const companyId = urlParams.get('companyId');
  const isTemplate = urlParams.get('isTemplate');
  const sort = urlParams.get('sort');
  const sortOrder = urlParams.get('sortDirection');

  const error = (param: string) =>
    `${param} is missing or invalid parameter type`;

  if (isNaN(Number(page)) || Number(page) < 0) return error('page');
  if (isNaN(Number(companyId)) || Number(companyId) < 0)
    return error('companyId');
  if (isTemplate && !(isTemplate === 'true' || isTemplate === 'false'))
    return error('isTemplate');
  if (sort && !isKeyOfAccountProfile(sort)) return error('sort');

  return {
    page: page !== null ? Number(page) : 0,
    companyId: companyId ? Number(companyId) : undefined,
    isTemplate:
      isTemplate === 'true' ? true : isTemplate === 'false' ? false : undefined,
    sortString: sort ?? undefined,
    sortOrder: sortOrder && isSortOrder(sortOrder) ? sortOrder : undefined,
  };
}

export function isKeyOfAccountProfile(
  value: string
): value is keyof AccountProfile {
  type A = keyof AccountProfile;
  const aKeys: Record<A, string> = {
    accountProfileId: '',
    companyId: '',
    companyName: '',
    isTemplate: '',
    name: '',
    startPage: '',
    userCount: ''
  };

  return value in aKeys;
}

export function useAccountProfileCreateParams(url: string): {
  accountProfileId: number;
} {
  const urlParams = new URLSearchParams(url);
  const accountProfileId = urlParams.get('accountProfileId');
  return {
    accountProfileId: Number(accountProfileId),
  };
}
