import { Repair } from 'api/resources/models/AutoGenerated';
import { ModifiedDisplay } from 'components/VehiclesTable/fields/Modified';
import { GridItem } from '../../components/Layout';
import { Text } from 'components/Typography';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOffOutlined';
import WarningAmber from '@mui/icons-material/WarningRounded';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import { RepairManualUpdate } from './form';
import {
  ActionButton,
  ActionText,
  InnerStatusContainer,
  ItemWrapper,
  MainStatusContainer,
  StatusHeader,
} from 'components/PageStatusLayout';
import { Label } from 'components/PageLayout';

interface Props {
  repair: Repair;
}

export default function RepairStatus({ repair }: Props) {
  const { control } = useFormContext<RepairManualUpdate>();
  return (
    <MainStatusContainer md={8} xs={12} direction="column">
      <GridItem>
        <InnerStatusContainer direction="column">
          <StatusHeader>Repair Status</StatusHeader>
          <ItemWrapper>
            <Label>Claim Reference Number</Label>
            <Text fontSize="l" noWrap>
              {repair.claimReference || '-'}
            </Text>
          </ItemWrapper>
          <ItemWrapper>
            <Label>Repair Number</Label>
            <Text fontSize="l" noWrap>
              {repair.repairCode || '-'}
            </Text>
          </ItemWrapper>
          <ItemWrapper>
            <Label>Vehicle Registration</Label>
            <Text fontSize="l" noWrap>
              {repair.vehicleReg || '-'}
            </Text>
          </ItemWrapper>
          <ItemWrapper>
            <Label>Last Updated</Label>
            <Text fontSize="l" noWrap>
              {repair.dtgUpdated ? (
                <ModifiedDisplay
                  modified={new Date(repair.dtgUpdated)}
                  delivered={
                    repair.dtgUpdated ? new Date(repair.dtgUpdated) : undefined
                  }
                />
              ) : (
                '-'
              )}
            </Text>
          </ItemWrapper>
        </InnerStatusContainer>
      </GridItem>
      <GridItem>
        <InnerStatusContainer direction="column">
          <StatusHeader>Repair Actions</StatusHeader>
          <ItemWrapper>
            <Controller
              render={({ field: { value, onChange } }) => (
                <ActionButton onClick={() => onChange(!value)}>
                  {value ? (
                    <ActionText>
                      <ToggleOn className="image" />
                      Driveable
                    </ActionText>
                  ) : (
                    <ActionText>
                      <ToggleOff className="image" />
                      Not Driveable
                    </ActionText>
                  )}
                </ActionButton>
              )}
              name="claimDetail.isVehicleDriveable"
              control={control}
            />
          </ItemWrapper>
          <ItemWrapper>
            <Controller
              render={({ field: { value, onChange } }) => (
                <ActionButton onClick={() => onChange(!value)}>
                  {value ? (
                    <ActionText>
                      <WarningAmber className="image" />
                      Possible total loss
                    </ActionText>
                  ) : (
                    <ActionText>
                      <CheckBoxIcon className="image" />
                      Repairable
                    </ActionText>
                  )}
                </ActionButton>
              )}
              name="claimDetail.possibleTL"
              control={control}
            />
          </ItemWrapper>
        </InnerStatusContainer>
      </GridItem>
    </MainStatusContainer>
  );
}
