import styled from '@emotion/styled';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalTitle } from 'components/Typography';

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: JSX.Element | JSX.Element[];
}

export function FilterModal({
  isOpen,
  onClose,
  title,
  children,
}: FilterModalProps) {
  return (
    <FilterModalContainer
      open={isOpen}
      onClose={handleOnClose}
      fullScreen={true}
    >
      <StyledDialogTitle>
        <ModalTitle>{title}</ModalTitle>
        {onClose && (
          <StyledIconButton onClick={onClose}>
            <CloseIcon />
          </StyledIconButton>
        )}
      </StyledDialogTitle>
      <Content>{children}</Content>
    </FilterModalContainer>
  );

  function onSubmit() {
    onClose();
  }

  function handleOnClose() {
    onSubmit();
  }
}

const FilterModalContainer = styled(Dialog)`
  padding-left: 56px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding-top: 13px;
  padding-left: 20px;
  padding-bottom: 12px;
`;
const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 5px;
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Content = styled(DialogContent)`
  padding: ${({ theme }) =>
    `${theme.padding.m} ${theme.padding.l} ${theme.padding.l} ${theme.padding.l}`};
`;
