import { AccountProfile as ApiAccountProfile } from 'api/resources/models/AutoGenerated';

export type AccountProfile = {
  accountProfileId: number;
  name: string | null;
  startPage: string | null;
  companyId: number;
  isTemplate: boolean;
  companyName: string | null;
  isCreatingAsCopy: boolean;
  userCount: number;
};

export const mapAccountProfiles = (
  item: ApiAccountProfile
): AccountProfile => ({ ...item, isCreatingAsCopy: true });
