import { Container } from './Layout';
import { DateStatusIcon } from './VehiclesTable/fields/DateStatusIcon';
import { DateStatus } from './VehiclesTable/fields/types';

interface Props {
  details: { status: DateStatus; description: string }[];
}

export default function PrimaryAccordionDetails({ details }: Props) {
  return (
    <Container direction="column">
      {details.map((item, index) => {
        return (
          <Container className="icon" key={index}>
            <DateStatusIcon status={item.status} /> {item.description}
          </Container>
        );
      })}
    </Container>
  );
}
