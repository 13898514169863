import dayjs from 'dayjs';

export const getMaintenanceCacheTimeMs = ({
  cachedOn,
  cacheLifeTimeInSeconds,
  now,
}: {
  cachedOn: Date;
  cacheLifeTimeInSeconds: number;
  now: Date;
}) => {
  const diffBetweenNowAndCachedAt = dayjs(now).diff(dayjs(cachedOn), 'seconds');
  const diffBiggerThanCacheLifeTime =
    diffBetweenNowAndCachedAt > cacheLifeTimeInSeconds;

  if (diffBiggerThanCacheLifeTime)
    throw new Error(
      'Difference between cacheAt and now is higher than cache life time value. ' +
        'Probably maintenance config received from API call is incorrect.'
    );
  return dayjs
    .duration(cacheLifeTimeInSeconds - diffBetweenNowAndCachedAt + 5, 'seconds')
    .asMilliseconds();
};
