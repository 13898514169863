import styled from '@emotion/styled';
import Status from './Status';

interface Props {
  color: string;
  toolTipMessage: string;
  description: string;
}

export default function StatusChip({
  color,
  toolTipMessage,
  description,
}: Props) {
  return (
    <Status toolTipMessage={toolTipMessage}>
      <Chip color={color}>
        <span>{description}</span>
      </Chip>
    </Status>
  );
}

const Chip = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.fontSize.xs};
  border-radius: ${({ theme }) => theme.border.radius};
  font-weight: bold;
  height: 24px;
  padding: 6px;
  display: flex;
  min-width: 110px;
  text-align: center;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.light};
  white-space: nowrap;
`;
