/** @jsxImportSource @emotion/react */
import { Container, GridItem } from 'components/Layout';
import { Text, Subheading } from 'components/Typography';
import styled from '@emotion/styled';
import { WithWrapper } from 'components/WithWrapper';
import Permits from 'components/Permits';
import { Information } from './helpers';

interface Props {
  title: string;
  data: Information[];
  id?: string;
}

export function InfoList({ data, title, id }: Props) {
  return (
    <StyledContainer id={id} direction="column" wrap="nowrap">
      <GridItem>
        <Title>{title}</Title>
      </GridItem>
      <GridItem>
        {data.map((information, i) => (
          <WithWrapper
            key={i}
            condition={!!information?.permit}
            wrap={(children) => (
              <Permits permit={information.permit!}>{children}</Permits>
            )}
          >
            <ItemContainer key={i} noBottomBorder={data.length - 1 === i}>
              <GridItem md={6} sm={6} xs={6}>
                <Text>{information.name}</Text>
              </GridItem>
              <ItemValue md={6} sm={6} xs={6} className="item-value">
                <Text>{information.value}</Text>
              </ItemValue>
            </ItemContainer>
          </WithWrapper>
        ))}
      </GridItem>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  margin-bottom: 10px;
`;

const Title = styled(Subheading)`
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

const ItemValue = styled(GridItem)`
  text-align: right;
`;

const ItemContainer = styled(Container)<{ noBottomBorder: boolean }>`
  border-bottom: ${({ noBottomBorder }) =>
    noBottomBorder ? '' : '1px solid #ddecf4 '};
  padding: 12px 0;
`;
