import { useState } from 'react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Tab, TabItem } from './Tab';

interface SpyItem {
  inView: boolean;
  element: HTMLElement;
  id: string;
}

interface ScrollspyProps {
  ids: string[];
}

export function Scrollspy({ ids }: ScrollspyProps) {
  const [spyItems, setSpyItems] = useState<SpyItem[]>([]);
  const [selectedSpyItem, setSelectedSpyItem] = useState<string>(ids[0]);
  useEffect(() => {
    spy();
    window.addEventListener('scroll', spy);
    return () => {
      window.removeEventListener('scroll', spy);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTab variant="fullWidth" value={selectedSpyItem}>
      {spyItems.map((item, k) => {
        return (
          <TabItem
            className={item.inView ? 'activeClassName' : ''}
            value={item.id}
            label={item.id}
            key={k}
            onClick={() => {
              scrollTo(item.id);
            }}
          ></TabItem>
        );
      })}
    </StyledTab>
  );

  function spy() {
    const items = ids
      .map((id) => {
        const element = document.getElementById(id);
        if (element) {
          return {
            inView: isInView(element),
            element,
            id,
          } as SpyItem;
        } else {
          return;
        }
      })
      .filter((item) => item);

    const firstTrueItem = items.find((item) => !!item && item.inView);

    if (!firstTrueItem) {
      return;
    } else {
      const update = items.map((item) => {
        setSelectedSpyItem(firstTrueItem.id);
        return { ...item, inView: item === firstTrueItem } as SpyItem;
      });

      setSpyItems(update);
    }
  }

  function isInView(element: HTMLElement) {
    if (!element) {
      return false;
    }
    const offset = (window.innerHeight * 6) / 100;
    const rect = element.getBoundingClientRect();
    return rect.top >= 0 - offset; //&& rect.bottom <= window.innerHeight + offset;
  }

  function scrollTo(id: string) {
    const elementToScrool = document.getElementById(id);
    if (elementToScrool) {
      window.scrollTo({
        behavior: 'smooth',
        top:
          id === ids[0]
            ? 0
            : elementToScrool.getBoundingClientRect().top +
              window.pageYOffset -
              60,
      });
    }
  }
}

const StyledTab = styled(Tab)`
  border-top: ${({ theme }) => `1px solid ${theme.palette.secondary.active}`};
`;
