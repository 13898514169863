import { useEffect, useState } from 'react';
import { Container, GridItem } from 'components/Layout';
import { SmallMainText, Text, Subheading } from 'components/Typography';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ServiceLevelAgreement } from '../helpers';

interface Props {
  item: ServiceLevelAgreement;
  initialExpandState?: boolean;
  onToggle?: () => void;
}

export function ServiceLegalItem({
  item,
  initialExpandState = false,
  onToggle,
}: Props) {
  const theme = useTheme();
  const [isExpanded, toggleExpand] = useState(initialExpandState);

  useEffect(() => {
    if (onToggle) onToggle();
  }, [isExpanded, onToggle]);

  return (
    <MainContainer>
      <GridItem className="container">
        <div onClick={() => toggleExpand(!isExpanded)}>
          <Container className="header-container" wrap="nowrap">
            <GridItem className="header-icon" xs="auto">
              {renderIcon()}
            </GridItem>
            <Container xs={12} wrap="nowrap">
              <GridItem xs="auto">
                <Subheading className="header-text">{item.title}</Subheading>
              </GridItem>
            </Container>
            <Container xs={4} justify="flex-end" wrap="nowrap">
              <GridItem xs="auto">{renderChip()}</GridItem>
              <GridItem xs={1}>
                {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </GridItem>
            </Container>
          </Container>
          {isExpanded ? (
            <Container className="main-container" alignItems="center">
              <GridItem className="main-description">
                <Text>{item.description}</Text>
              </GridItem>
              <GridItem className="dates-container">
                <Container className="main-date">
                  <GridItem md={6} xs={12}>
                    <Container direction="column">
                      <GridItem>
                        <SmallMainText>Start Date</SmallMainText>
                      </GridItem>
                      <GridItem>
                        <Text>{item.startDate}</Text>
                      </GridItem>
                    </Container>
                  </GridItem>
                  <GridItem md={6} xs={12}>
                    <ExpectedDate direction="column">
                      <GridItem>
                        <SmallMainText>Expected Date</SmallMainText>
                      </GridItem>
                      <GridItem>
                        <Text>{item.expectedValue}</Text>
                      </GridItem>
                    </ExpectedDate>
                  </GridItem>
                </Container>
                <Container className="main-date">
                  <GridItem md={6} xs={12}>
                    <Container direction="column">
                      <GridItem>
                        <SmallMainText>Actual Date</SmallMainText>
                      </GridItem>
                      <GridItem>
                        <Text>{item.actualValue}</Text>
                      </GridItem>
                    </Container>
                  </GridItem>
                </Container>
              </GridItem>
            </Container>
          ) : null}
        </div>
      </GridItem>
    </MainContainer>
  );

  function renderIcon() {
    if (item.icon?.includes('Pass')) {
      return (
        <CheckCircleIcon
          htmlColor={theme.palette.icon.green}
          className="header-icon"
        />
      );
    }
    if (item.icon?.includes('Fail')) {
      return (
        <CancelIcon
          htmlColor={theme.palette.icon.red}
          className="header-icon"
        />
      );
    }
    if (item.icon?.includes('Warn')) {
      return (
        <ErrorIcon
          htmlColor={theme.palette.icon.warn}
          className="header-icon"
        />
      );
    }
  }

  function renderChip() {
    if (item.status?.toLowerCase().includes('completed'))
      return (
        <Chip isCompleted>
          <Text fontSize="xs" fontWeight="bold">
            Completed
          </Text>
        </Chip>
      );
    else
      return (
        <Chip>
          <Text fontSize="xs" fontWeight="bold">
            In Progress
          </Text>
        </Chip>
      );
  }
}

const MainContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-bottom: ${({ theme }) => theme.margin.s};
  border-radius: ${({ theme }) => theme.border.radius};

  & .container {
    width: 100%;

    & .header-container {
      padding: ${({ theme }) => theme.padding.l};
      display: flex;
      align-items: center;
      & .header-icon {
        display: flex;
      }
      & .header-text {
        margin-left: ${({ theme }) => theme.margin.xs};
        margin-right: ${({ theme }) => theme.margin.s};
        font-size: ${({ theme }) => theme.fontSize.l};
        flex: 1;
      }
    }

    & .main-container {
      padding: ${({ theme }) =>
    `0 ${theme.padding.l} ${theme.padding.l} ${theme.padding.l}`};

      & .dates-container {
        width: 100%;

        & .main-date {
          margin-top: ${({ theme }) => theme.padding.m};
          width: 100%;
        }
      }
    }
  }

  :hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }

  :active {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ExpectedDate = styled(Container)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: ${({ theme }) => theme.padding.m};
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-top: ${({ theme }) => theme.padding.m};
  }
`;

const Chip = styled.div<{ isCompleted?: boolean }>`
  margin-right: ${({ theme }) => theme.margin.s};
  background-color: ${({ theme, isCompleted }) =>
    isCompleted ? theme.palette.primary.main : theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 5px 6px 6px 6px;
  p {
    white-space: nowrap;
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;
