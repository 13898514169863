import styled from '@emotion/styled';
import { InputAdornment } from '@mui/material';
import {
  SiteCodeFormalCodeSearchResult,
  UserWPSecurityPermitStatus,
} from 'api/resources/models/AutoGenerated';
import { PrimaryButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { TextField } from 'components/TextField';
import { Text } from 'components/Typography';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  siteCode: string;
  formalCode: string;
  searchResult: SiteCodeFormalCodeSearchResult;
  onAdd: () => void;
  onRemove: () => void;
}

export function SearchResult({
  searchResult,
  siteCode,
  formalCode,
  onAdd,
  onRemove,
}: Props) {
  if (!searchResult.exists)
    return (
      <NothingFound>{`${siteCode}/${formalCode} pair not found.`}</NothingFound>
    );

  return (
    <SearchResultItem xs={12} direction="row">
      <Item md={8} xs={12}>
        <Label noWrap fontSize="xs">
          Search Result
        </Label>
        <TextInput
          disabled
          fullWidth
          variant="outlined"
          value={`${siteCode}/${formalCode}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Item>
      <Item md={2}>
        {searchResult.allowed ? (
          <Button
            disabled={
              searchResult.status ===
              UserWPSecurityPermitStatus.PermitByWPIdMask
            }
            onClick={onRemove}
          >
            Remove
          </Button>
        ) : (
          <Button onClick={onAdd}>Add</Button>
        )}
      </Item>
    </SearchResultItem>
  );
}

const NothingFound = styled(Text)`
  margin-top: ${({ theme }) => theme.padding.m};
`;

const SearchResultItem = styled(Container)`
  padding-top: ${({ theme }) => theme.padding.l};
`;

const Label = styled(Text)`
  margin-bottom: ${({ theme }) => theme.padding.xs};
`;

const Item = styled(GridItem)`
  padding-right: ${({ theme }) => theme.padding.s};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-bottom: ${({ theme }) => theme.padding.s};
    padding-right: 0px;
  }
`;

const TextInput = styled(TextField)`
  margin-bottom: 8px;
  fieldset {
    border: ${({ theme }) => `1px solid ${theme.palette.primary.border}`};
    box-shadow: ${({ theme }) => theme.shadow.s};
  }
  .Mui-disabled {
    background-color: ${({ theme }) => theme.palette.primary.light};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Button = styled(PrimaryButton)`
  margin-top: 20.5px;
  font-size: ${({ theme }) => theme.fontSize.s};
  height: 40px;
  padding: 12px;
`;
