import { SortOrder } from 'api/resources/models/AutoGenerated';
import { isKeyOfRepair } from 'pages/VehiclesSearch/hooks';
import { isSortOrder } from 'utils/helpers';

type RepairsSearchParams = {
  searchTerm: string | null;
  sort: string | null;
  page: number;
  sortOrder: SortOrder;
  registration: string | null;
  claimReferece: string | null;
};

export function useRepairsSearchParams(search: string): RepairsSearchParams {
  const urlParams = new URLSearchParams(search);

  const searchTerm = urlParams.get('searchString');
  const sort = urlParams.get('sort');
  const page = urlParams.get('page');
  const sortOrder = urlParams.get('sortDirection');
  const registration = urlParams.get('registration');
  const claimReference = urlParams.get('claimReference');
  return {
    searchTerm: searchTerm,
    sort: isKeyOfRepair(sort ?? '') ? sort : '',
    page: Number(page),
    sortOrder:
      sortOrder !== null && isSortOrder(sortOrder)
        ? sortOrder
        : SortOrder.Ascending,
    registration: registration,
    claimReferece: claimReference,
  };
}
