import { signoutRedirectCallback } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function SignoutOidc() {
  const history = useHistory();

  useEffect(() => {
    async function signoutAsync() {
      await signoutRedirectCallback();
      history.push(ROUTES.redirectHandler);
    }
    signoutAsync();
  }, [history]);

  return <div>Redirecting...</div>;
}
