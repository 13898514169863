import { PrimaryDatePicker, PrimaryDateTimePicker } from './DatePicker';

interface Props {
  onChange: (date: Date | null) => void;
  value: Date | null;
  isDisabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
}

export function DatePickerField({
  onChange,
  value,
  isDisabled = false,
  minDate,
  maxDate
}: Props) {
  return (
    <PrimaryDatePicker
      onChange={(date) => onChange(date)}
      selected={value}
      disabled={isDisabled}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}

export function DateTimePickerField({
  onChange,
  value,
  isDisabled = false,
}: Props) {
  return (
    <PrimaryDateTimePicker
      onChange={(date) => onChange(date)}
      selected={value}
      disabled={isDisabled}
    />
  );
}
