import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { ReplacementVehicleStatus } from 'api/resources/models/AutoGenerated';

interface StatusProps {
  status?: string;
  toolTipMessage: string;
  children: JSX.Element;
  isTooltipVisible?: boolean;
}

export default function Status({
  status,
  toolTipMessage,
  children,
  isTooltipVisible = true,
}: StatusProps) {
  return (
    <StyledIcon status={status}>
      {isTooltipVisible ? (
        <Tooltip title={toolTipMessage}>{children}</Tooltip>
      ) : (
        children
      )}
    </StyledIcon>
  );
}

const StyledIcon = styled.div<{ status?: string }>`
  opacity: ${(props) =>
    props.status === ReplacementVehicleStatus.IsRequired ||
      props.status === 'NotStarted'
      ? 0.3
      : props.status === ReplacementVehicleStatus.AssignedOut ||
        props.status === 'InProgress'
        ? 0.6
        : 1};
`;
