import { Heading } from './Typography';
import { VehiclesSearchFilters } from '../pages/VehiclesSearch/VehiclesSearchFilters';
import { Repairs } from '../api/resources/models/AutoGenerated';
import styled from '@emotion/styled';
import { ScrollContext } from 'core/ScrollContext';
import { useContext, useEffect } from 'react';
import {
  pagePaddingLeft,
  pagePaddingRight,
  StyledGridItem,
} from './PageLayout';
import { Theme } from '@emotion/react';

interface Props {
  headerText: string;
  repairs?: Repairs;
  selectedRepairerId?: number;
  selectedRepairGroup?: string;
  selectedFilterId?: number;
  onApply: () => void;
  onRepairerIdChange: (selectedRepairerId: number | undefined) => void;
  onRepairerGroupChange: (selectedRepairerGroup: string | undefined) => void;
  onFilterIdChange: (filterId: number) => void;
  onScroll: (isFixed: boolean) => void;
}

export default function VehicleUpperInfo({
  headerText,
  repairs,
  onApply,
  selectedRepairerId,
  selectedRepairGroup,
  selectedFilterId,
  onRepairerIdChange,
  onRepairerGroupChange,
  onFilterIdChange,
  onScroll,
}: Props) {
  const scrollPosition = useContext(ScrollContext);
  const isContainerFixed = scrollPosition !== undefined;

  useEffect(() => {
    onScroll(isContainerFixed);
  });

  return (
    <>
      {isContainerFixed ? (
        <FixedUpperInfoContainer
          style={{ transform: `translateY(${-scrollPosition!}px)` }}
        >
          {renderVehiclesSearchFilters()}
        </FixedUpperInfoContainer>
      ) : (
        <>{renderVehiclesSearchFilters()}</>
      )}
    </>
  );

  function renderVehiclesSearchFilters() {
    return (
      <>
        <Heading>{headerText}</Heading>
        <StyledGridItem>
          <VehiclesSearchFilters
            repairs={repairs}
            onRepairerIdChange={onRepairerIdChange}
            onRepairerGroupChange={onRepairerGroupChange}
            onFilterIdChange={onFilterIdChange}
            onApply={onApply}
            selectedRepairerId={selectedRepairerId}
            selectedRepairerGroup={selectedRepairGroup}
            selectedFilterId={selectedFilterId}
          />
        </StyledGridItem>
      </>
    );
  }
}

const getFixedActivityContainerRightPadding = (theme: Theme) =>
  parseInt(pagePaddingLeft(theme)) +
  parseInt(pagePaddingRight(theme)) +
  theme.palette.drawer.width;

const FixedUpperInfoContainer = styled('div')`
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 999;
  & .activity-container {
    padding-right: ${({ theme }) =>
      getFixedActivityContainerRightPadding(theme)}px;
  }
`;
