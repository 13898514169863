import styled from '@emotion/styled';
import { Scrollspy } from 'components/ScrollSpy';

export function NavigationHeader() {
  return (
    <Sticky>
      <Scrollspy
        ids={['Details', 'Milestones', 'Claim', 'SLAs', 'Activities']}
      />
    </Sticky>
  );
}

const Sticky = styled.nav`
  position: sticky;
  z-index: 99;
  top: 0px;
  right: 0px;
  left: 0px;
  display: none;
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    display: block;
  }
`;
