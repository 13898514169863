import styled from '@emotion/styled';
import { Checkbox } from 'components/Checkbox';
import { Container, GridItem } from 'components/Layout';
import { Controller, useFormContext } from 'react-hook-form';
import { AccountProfileForm } from './form';

export function AccountProfileReports() {
  const { control, getValues, watch, setValue } =
    useFormContext<AccountProfileForm>();
  const permit = getValues('permits.reportPermits');
  watch('permits.reportPermits.isActive');
  return (
    <Container direction="column">
      <Controller
        render={({ field }) => (
          <Checkbox
            {...field}
            label={`${permit.name} (${permit.permit})`}
            checked={field.value}
            onChange={(e) => {
              field.onChange(e);
              permit.children?.forEach((_child, index) =>
                setValue(
                  `permits.reportPermits.children.${index}.isActive`,
                  false
                )
              );
            }}
          />
        )}
        name={`permits.reportPermits.isActive`}
        control={control}
        defaultValue={false}
      />
      {permit.children?.map((child, index) => (
        <ChildContainer key={child.accountProfilePermitId}>
          <Controller
            render={({ field }) => (
              <Checkbox
                {...field}
                disabled={!permit.isActive}
                label={child.name}
                checked={!permit.isActive ? false : field.value}
                onChange={(e) => field.onChange(e)}
              />
            )}
            name={`permits.reportPermits.children.${index}.isActive`}
            control={control}
            defaultValue={false}
          />
        </ChildContainer>
      ))}
    </Container>
  );
}

const ChildContainer = styled(GridItem)`
  padding-bottom: ${({ theme }) => theme.padding.s};
  margin-left: ${({ theme }) => theme.margin.l};
`;
