import { useParams } from 'react-router-dom';
import { useRepairClaimSummary, useRepairDetails } from 'pages/hooks';
import { GrayBackdrop, Loader } from 'components/Loader';
import { ManualUpdateForm } from './ManualUpdateForm';
import { VehicleDetailsRouteParams } from 'core/routes';
import { ManualUpdateSkeleton } from './ManualUpdateSkeleton';
import { Text } from 'components/Typography';
import { UpperInfoContainer } from 'components/PageLayout';

const useDetailsParams = () => {
  const { repairId: rawRepairId, repairCode } =
    useParams<VehicleDetailsRouteParams>();
  const repairId = Number(rawRepairId);
  return {
    repairId: isNaN(repairId) ? 'RepairID must be a number' : repairId,
    repairCode,
  };
};
export function ManualUpdate() {
  const { repairId, repairCode } = useDetailsParams();
  if (typeof repairId === 'string') throw new Error(repairId);
  const { repairDetails, isRepairDetailsLoading } = useRepairDetails(repairId);
  const { repairSummary, isRepairSummaryLoading } = useRepairClaimSummary(
    repairId,
    repairCode
  );

  const isBusy = isRepairDetailsLoading || isRepairSummaryLoading;

  return repairSummary && repairDetails ? (
    repairSummary.repairer?.requiresManualRepairUpdate ? (
      <>
        <GrayBackdrop open={isBusy}>
          <Loader />
        </GrayBackdrop>
        <ManualUpdateForm
          repairSummary={repairSummary}
          repairDetails={repairDetails}
        />
      </>
    ) : (
      <UpperInfoContainer>
        <Text
          fontSize="xl"
          color="red"
        >{`Manual update for repairer ${repairSummary.repairer?.name} is not allowed`}</Text>
      </UpperInfoContainer>
    )
  ) : (
    <ManualUpdateSkeleton />
  );
}
