import { DataGrid } from "components/DataGrid";
import InfoText from "components/InfoText";
import { MobileRow } from "pages/UserAccounts/MobileRow";
import { convertStringToDate, useUserAccountsByProfileId } from 'pages/hooks';
import { usersTableHeaders } from "./types";

interface Props {
    accountProfileId: number | undefined;
}

export function AccountProfileUserAccounts({ accountProfileId }: Props) {
    const { userAccounts, isUserAccountsLoading } = useUserAccountsByProfileId({ accountProfileId }, true);
    const isBusy = isUserAccountsLoading;
    const user = convertStringToDate(userAccounts);

    if (!isBusy && user && user.length === 0) {
        return (
            <InfoText>
                {"Sorry, we couldn't find any users associated with this profile."}
            </InfoText >
        )
    }

    return (
        <DataGrid
            removeLeftPadding
            isLoading={isBusy}
            headers={usersTableHeaders}
            data={user}
            mobileRow={<MobileRow users={user} />}
        />
    )
}