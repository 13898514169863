import { silentSigninCallback } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function SilentSigninOidc() {
  const history = useHistory();

  useEffect(() => {
    async function silentSignInAsync() {
      await silentSigninCallback();
      history.push(ROUTES.redirectHandler);
    }
    silentSignInAsync();
  }, [history]);

  return <></>;
}
