import { signinRedirectCallback } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function SigninOidc() {
  const history = useHistory();

  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback();
      history.push(ROUTES.redirectHandler);
    }
    signinAsync();
  }, [history]);
  return <></>;
}
