import { mapMenuItems } from 'components/Menu/helpers';
import { topMenuItems } from 'components/Menu/types';
import { ROUTES } from 'core/routes';
import { Redirect } from 'react-router-dom';
import { useUser } from './hooks';

export function RedirectToStartPageHandler() {
  const { user, isFetching } = useUser();

  if (isFetching || !user) return null;

  const restrictableMenuItems = mapMenuItems(
    topMenuItems,
    user.userPermits
  ).filter((permit) => permit.permitCode !== undefined);

  if (restrictableMenuItems.length === 1) {
    const menuItem = restrictableMenuItems[0].route;
    return <Redirect to={menuItem} />;
  }
  return <Redirect to={ROUTES.dashboard} />;
}
