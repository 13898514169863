import {
  AccountProfile,
  AccountProfilePermits,
} from 'api/resources/models/AutoGenerated';
import { BackButton } from 'components/Button';
import { Container, GridItem } from 'components/Layout';
import { GrayBackdrop, Loader } from 'components/Loader';
import { PageHeader, UpperInfoContainer } from 'components/PageLayout';
import { Heading } from 'components/Typography';
import { AccountProfileParams } from 'core/routes';
import { useAccountProfileCreateParams } from 'pages/AccountProfiles/hooks';
import { useAccountProfile, useAccountProfilePermits } from 'pages/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { AccountProfileDetailsForm } from './AccountProfileDetailsForm';

export type AccountProfileMode = 'Edit' | 'Create';

const useAccountProfileParams = () => {
  const { id } = useParams<AccountProfileParams>();
  const accountProfileId = Number(id);
  return isNaN(accountProfileId)
    ? 'Account profile id must be a number'
    : accountProfileId;
};

export function AccountProfileCreate() {
  const location = useLocation();
  const urlParams = useAccountProfileCreateParams(location.search);
  const { accountProfile, isLoading } = useAccountProfile({
    accountProfileId: urlParams.accountProfileId,
    enabled: !!urlParams.accountProfileId,
  });
  const { accountProfilePermits, isLoading: isPermitsLoading } =
    useAccountProfilePermits(
      urlParams.accountProfileId !== 0 ? urlParams.accountProfileId : undefined
    );
  const isBusy = isLoading || isPermitsLoading;

  if (accountProfilePermits && !urlParams.accountProfileId)
    return (
      <AccountProfileDetails
        accountProfilePermits={accountProfilePermits}
        mode="Create"
      />
    );

  return accountProfilePermits && accountProfile ? (
    <AccountProfileDetails
      mode="Create"
      accountProfile={accountProfile}
      accountProfilePermits={accountProfilePermits}
    />
  ) : (
    <GrayBackdrop open={isBusy}>
      <Loader />
    </GrayBackdrop>
  );
}

export function AccountProfileEdit() {
  const accountProfileId = useAccountProfileParams();
  if (typeof accountProfileId === 'string') throw Error(accountProfileId);
  const { accountProfile, isLoading } = useAccountProfile({
    accountProfileId: accountProfileId,
    enabled: true,
  });
  const { accountProfilePermits, isLoading: isPermitsLoading } =
    useAccountProfilePermits(accountProfileId);
  const isBusy = isLoading || isPermitsLoading;

  return accountProfile && accountProfilePermits ? (
    <AccountProfileDetails
      mode="Edit"
      accountProfile={accountProfile}
      accountProfilePermits={accountProfilePermits}
    />
  ) : (
    <GrayBackdrop open={isBusy}>
      <Loader />
    </GrayBackdrop>
  );
}

export interface AccountProfileProps {
  accountProfile?: AccountProfile;
  accountProfilePermits: AccountProfilePermits;
  mode: AccountProfileMode;
}

function AccountProfileDetails({
  accountProfile,
  mode,
  accountProfilePermits,
}: AccountProfileProps) {
  return (
    <UpperInfoContainer direction="column">
      <GridItem>
        <Container>
          <BackButton text="Back to Account Profiles" />
        </Container>
      </GridItem>
      <PageHeader>
        <Heading>
          {mode === 'Edit' && accountProfile
            ? `Edit Account Profile "${accountProfile.name}"`
            : mode === 'Create' && accountProfile
            ? `Copied from "${accountProfile.name}"`
            : 'Create New Account Profile'}
        </Heading>
      </PageHeader>
      <AccountProfileDetailsForm
        accountProfile={accountProfile}
        accountProfilePermits={accountProfilePermits}
        mode={mode}
      />
    </UpperInfoContainer>
  );
}
