import { Checkbox } from 'components/Checkbox';
import { Container } from 'components/Layout';
import { Controller, useFormContext } from 'react-hook-form';
import { AccountProfileForm } from './form';

export function AccountProfileRecordFilters() {
  const { control, getValues } = useFormContext<AccountProfileForm>();
  const permits = getValues('permits.recordFiltersPermits');
  return (
    <Container direction="column">
      {permits.map((permit, index) => (
        <Controller
          key={permit.accountProfilePermitId}
          render={({ field }) => (
            <Checkbox
              {...field}
              label={`${permit.name}`}
              checked={field.value}
              onChange={field.onChange}
            />
          )}
          name={`permits.recordFiltersPermits.${index}.isActive`}
          control={control}
          defaultValue={false}
        />
      ))}
    </Container>
  );
}
