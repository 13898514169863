import { signinRedirect } from 'core/auth/userService';
import { ROUTES } from 'core/routes';
import { useAppSelector } from 'core/store';
import { Redirect } from 'react-router';

export function Login() {
  const user = useAppSelector((state) => state.auth.user);

  if (!user) signinRedirect();

  return user ? <Redirect to={ROUTES.redirectHandler} /> : null;
}
