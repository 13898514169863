import { api } from 'api';
import { config } from 'core/config';
import { HelpContext, User } from './models/AutoGenerated';

const userBaseUrl = '/api/currentUser';

export const getUser = (): Promise<User> =>
  api
    .get(`${config.activewebApiUrl}${userBaseUrl}`)
    .then((result) => result.data);

export const getHelpContext = (): Promise<HelpContext> =>
  api
    .get(`${config.activewebApiUrl}${userBaseUrl}/help`)
    .then((response) => response.data);
