/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Container, GridItem } from 'components/Layout';
import { Text } from 'components/Typography';
import { getActivity } from 'utils/helpers';
import { Repairs } from '../../api/resources/models/AutoGenerated';

interface Props {
  repairs?: Repairs;
  device: 'mobile' | 'desktop';
  showActivity: boolean | null;
}

export function Activity({ device, showActivity, repairs }: Props) {
  const theme = useTheme();
  const { activity, isOlder } = getActivity(
    repairs?.dtgUpdated ? new Date(repairs.dtgUpdated) : null
  );

  return repairs ? (
    <ActivityContainer>
      <Container className="activity-container">
        <GridItem>
          {activity && showActivity ? (
            <Container className="activity-text">
              <AccessTimeIcon
                className="activity-icon"
                htmlColor={
                  isOlder
                    ? theme.palette.messages.error
                    : theme.palette.messages.success
                }
              />
              <Text
                color={
                  isOlder
                    ? theme.palette.messages.error
                    : theme.palette.messages.success
                }
              >
                {device === 'mobile'
                  ? `Updated ${activity}`
                  : `Last updated ${activity}`}
              </Text>
            </Container>
          ) : null}
        </GridItem>
      </Container>
    </ActivityContainer>
  ) : null;
}

const ActivityContainer = styled(Container)`
  align-items: center;
  flex: 1;
  margin-left: ${({ theme }) => theme.margin.m};
`;
