import { BaseUserAccount } from 'api/resources/models/AutoGenerated';

export interface UsersTableHeader {
  id: keyof BaseUserAccount;
  label: string;
}

export const usersTableHeaders: UsersTableHeader[] = [
  { id: 'firstName', label: 'First Name' },
  { id: 'surname', label: 'Surname' },
  { id: 'userName', label: 'User Name' },
  { id: 'email', label: 'Email' },
  { id: 'teamName', label: 'Team Name' },
  { id: 'companyName', label: 'Company' },
  { id: 'isLocked', label: 'Locked' },
  { id: 'isDisabled', label: 'Disabled' },
  { id: 'createdDate', label: 'Created Date' },
  { id: 'expiryDate', label: 'Expiry Date' },
  { id: 'lastLoginDate', label: 'Last Login' },
  { id: 'usageCount', label: 'Times used' },
];
