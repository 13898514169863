import styled from '@emotion/styled';
import { Skeleton as MaterialSkeleton } from '@mui/material';

const percentage = (startingPoint: number) => {
  if (startingPoint < 0 || startingPoint > 1) throw new Error('Invalid number');

  return startingPoint * 100;
};

const getFraction = (startingPoint: StartingPoint): number => {
  if (startingPoint === 'start') return 0.115;
  if (startingPoint === 'middle') return 0.333;
  if (startingPoint === 'end') return 0.897;

  throw new Error(`unknown starting point: '${startingPoint}'`);
};

const getPercentage = (
  startingPoint: number | StartingPoint = 'middle'
): number => {
  const fraction =
    typeof startingPoint === 'number'
      ? startingPoint
      : getFraction(startingPoint);

  return percentage(fraction);
};
type StartingPoint = 'start' | 'middle' | 'end';

const Skeleton = styled(MaterialSkeleton) <{
  startingPoint?: number | 'start' | 'middle' | 'end';
}>`
  border-radius: ${({ theme }) => theme.border.radius};
  background: linear-gradient(
      270deg,
      rgba(218, 233, 241, 0) 0%,
      #dae9f1 ${({ startingPoint }) => getPercentage(startingPoint)}%,
      rgba(218, 233, 241, 0) 100%
    ),
    #f1f8fc;
`;

export default Skeleton;
