import { ReactComponent as PreOnSiteIcon } from 'assets/PreOnSiteIcon.svg';
import { ReactComponent as OnSiteIcon } from 'assets/OnSiteIcon.svg';
import { ReactComponent as DeliveredIcon } from 'assets/DeliveredIcon.svg';
import Status from 'components/Status';

interface Props {
  status: string | null;
}

export function WorkingInProgress({ status }: Props) {
  return <>{renderIcon(status)}</>;
}

function renderIcon(status: string | null) {
  switch (status) {
    case 'NotStarted':
      return (
        <Status status={status} toolTipMessage="Not Started">
          <PreOnSiteIcon />
        </Status>
      );
    case 'InProgress':
      return (
        <Status status={status} toolTipMessage="In Progress">
          <OnSiteIcon />
        </Status>
      );
    case 'Completed':
      return (
        <Status status={status} toolTipMessage="Completed">
          <DeliveredIcon />
        </Status>
      );
    default:
      break;
  }
}
