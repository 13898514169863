import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { ROUTES } from './routes';
import { useAppSelector } from './store';
import RouteWithPermit from 'core/RouteWithPermit';
import { useUser } from 'pages/hooks';
import { Permit } from 'api/resources/models/AutoGenerated';

interface Props {
  component?: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
  anyOfPermits?: Permit[];
}

export default function PrivateRoute(props: Props) {
  const authUser = useAppSelector((state) => state.auth.user);
  const { user } = useUser();
  if (!authUser) return <Redirect to={ROUTES.login} />;
  if (props.anyOfPermits)
    return (
      <RouteWithPermit
        path={props.path}
        exact={props.exact}
        component={props.component}
        anyOfPermits={props.anyOfPermits}
      />
    );
  if (user)
    return (
      <Route
        path={props.path}
        exact={props.exact}
        component={props.component}
      />
    );
  return <></>;
}
