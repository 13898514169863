import styled from '@emotion/styled';
import { BaseUserAccount } from 'pages/hooks';
import { Container, GridItem } from 'components/Layout';
import { Text } from 'components/Typography';

interface Props {
  users?: BaseUserAccount[];
  onClick?: (user: BaseUserAccount) => void;
}

export function MobileRow({ users, onClick }: Props) {
  return (
    <Container>
      {users?.map((user) => (
        <Row key={user.id} direction="column" onClick={() => onClick?.(user)}>
          <Line />
          <ContentContainer>
            <Cell>
              <Text
                fontWeight="bold"
                fontSize="l"
              >{`${user.firstName} ${user.surname}`}</Text>
            </Cell>
            <Cell>
              <GridItem md={6} sm={6} xs={6}>
                <Label fontSize="xs">User Name</Label>
                <Text fontSize="m">{user.userName?.toUpperCase()}</Text>
              </GridItem>
              <GridItem md={6} sm={6} xs={6}>
                <Label fontSize="xs">Email</Label>
                <EmailValue fontSize="m">{user.email}</EmailValue>
              </GridItem>
            </Cell>
            <Container>
              <GridItem md={6} sm={6} xs={6}>
                <Label fontSize="xs">Team Name</Label>
                <Text fontSize="m">{user.teamName?.toUpperCase()}</Text>
              </GridItem>
              <GridItem md={6} sm={6} xs={6}>
                <Label fontSize="xs">Company</Label>
                <Text fontSize="m">{user.companyName}</Text>
              </GridItem>
            </Container>
          </ContentContainer>
        </Row>
      ))}
    </Container>
  );
}

const EmailValue = Text;

const ContentContainer = styled(Container)`
  padding: 0 15px;
  word-break: break-all;
`;

const Row = styled(Container)`
  padding-top: ${({ theme }) => theme.padding.m};
`;

const Line = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.palette.primary.border}`};
  padding-bottom: ${({ theme }) => theme.padding.m};
`;

const Cell = styled(Container)`
  padding-bottom: ${({ theme }) => theme.padding.m};
`;

const Label = styled(Text)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;
