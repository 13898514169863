import { StyledGridItem, UpperInfoContainer } from 'components/PageLayout';
import { Heading } from 'components/Typography';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MainContainer } from '.';
import { VehiclesSearchFilters } from './VehiclesSearchFilters';

export function VehiclesLanding({
  headerText,
  navigateTo,
}: {
  headerText: string;
  navigateTo: (repairerId: number, filterId: number) => string;
}) {
  const history = useHistory();

  const [repairerId, setRepairerId] = useState<number | undefined>();
  const [group, setGroup] = useState<string | undefined>();

  const [filterId, setFilterId] = useState<number>();
  return (
    <MainContainer direction="column">
      <UpperInfoContainer direction="column">
        <Heading className="subheading">{headerText}</Heading>
        <StyledGridItem>
          <VehiclesSearchFilters
            onRepairerIdChange={setRepairerId}
            onRepairerGroupChange={setGroup}
            onFilterIdChange={setFilterId}
            selectedRepairerId={repairerId}
            selectedRepairerGroup={group}
            selectedFilterId={filterId}
            onApply={onApply}
          />
        </StyledGridItem>
      </UpperInfoContainer>
    </MainContainer>
  );

  function onApply() {
    if (repairerId !== undefined && repairerId >= 0 && filterId) {
      history.replace(navigateTo(repairerId, filterId));
    }
  }
}
