import dayjs, { Dayjs } from 'dayjs';
import { DateStatus } from './types';

export function getBookedInDetailsStatus(
  now: Dayjs,
  onSite?: Dayjs,
  bookedIn?: Dayjs
): DateStatus {
  if (!onSite && !bookedIn) return 'grey';
  if (bookedIn) {
    if (bookedIn > now && !onSite) return 'green';
    if (bookedIn < now && !onSite) return 'amber';
  }
  if (!bookedIn && onSite) return 'grey';
  if (onSite && bookedIn) {
    const isSameDate = dayjs(onSite).isSame(dayjs(bookedIn), 'date');
    if (isSameDate) return 'green';
    if (!isSameDate) return 'green';
  }
  return 'unknown';
}
