import { Container } from 'components/Layout';
import Countdown from 'react-countdown';

interface Props {
  message: string;
  countTillDate: Date;
}
export function MaintenanceWarning({ message, countTillDate }: Props) {
  return (
    <Container direction="column">
      <p>{message}</p>
      <Countdown date={countTillDate} />
    </Container>
  );
}
