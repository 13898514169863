import { DataAccessProfile, Repair } from "api/resources/models/AutoGenerated";

export const disabledVehicleSearchColumns: (keyof Repair)[] = [
  'replacementVehicleStatus',
  'workInProgressCode',
];

export const disabledCompanySearchColumns: (keyof DataAccessProfile)[] = [
  'companyId',
  'companyName',
];
