/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react';
import { Container, GridItem } from 'components/Layout';
import { Subheading } from 'components/Typography';
import styled from '@emotion/styled';
import Permits from 'components/Permits';
import { QuickDetails as QuickDetailsProps } from './helpers';
import { WithWrapper } from 'components/WithWrapper';
// import { DesktopContainer } from 'core/Theming/Device';
// import { Activity } from 'pages/VehiclesSearch/Activity';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Text } from 'components/Typography';
import { getActivity } from 'utils/helpers';

interface Props {
  data: QuickDetailsProps[];
  dtgUpdated: string | null
}

export function QuickDetails({ data, dtgUpdated }: Props) {
  const theme = useTheme();
  const { activity, isOlder } = getActivity(
    dtgUpdated ? new Date(dtgUpdated) : null
  );
  return (
    <Container direction="column">
      <Subheading
        css={css`
          margin-bottom: ${theme.margin.m};
        `}
      >
        Quick Details
      </Subheading>
      <Container direction="row">

        {data.map((details, i) => (
          <WithWrapper
            key={i}
            condition={!!details.permit}
            wrap={(children) => (
              <Permits permit={details.permit!}>{children}</Permits>
            )}
          >
            <Item direction="column">
              <GridItem>
                <SecondaryHeader>{details.title}</SecondaryHeader>
              </GridItem>
              <GridItem>
                <ValueText>{details?.value}</ValueText>
              </GridItem>
            </Item>
          </WithWrapper>
        ))}
        <TimeStampContainer>
          <TimeStampMain>
            <AccessTimeIcon
              className="activity-icon"
              htmlColor={
                isOlder
                  ? theme.palette.messages.error
                  : theme.palette.messages.success
              }
            />
            <Text
              css={css`
              margin-left: ${theme.margin.xs};
            `}
              color={
                isOlder
                  ? theme.palette.messages.error
                  : theme.palette.messages.success
              }
            >
              {`Last updated ${activity} ago`}
            </Text>
          </TimeStampMain>
        </TimeStampContainer>
      </Container>
    </Container>
  );
}

const ValueText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSize.l};
  word-break: break-word;
  white-space: pre-wrap;
`;

const SecondaryHeader = styled.header`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;

const TimeStampContainer = styled.div`
  margin-left: auto;
`;

const TimeStampMain = styled.div`
  display: flex; 
  flex-direction: row; 
  align-items: center 
`;

const Item = styled(Container)`
  max-width: 136px;
  margin-right: ${({ theme }) => theme.margin.s};

  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    margin-right: ${({ theme }) => theme.margin.xl};
    margin-bottom: 18px;
  }
`;
