import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import Skeleton from 'components/Skeleton';

export function ManualUpdateSkeleton() {
  return (
    <MainContainer>
      <Container direction="column">
        <GridItem>
          <Container>
            <SkeletonLoader
              variant="rectangular"
              height={40}
              width={300}
              startingPoint="start"
            />
          </Container>
          <Container>
            <SkeletonLoader
              variant="rectangular"
              height={60}
              width={250}
              startingPoint="start"
            />
          </Container>
        </GridItem>
      </Container>
      <Container direction="column">
        <SkeletonLoader variant="rectangular" height={40} startingPoint="middle" />
        <Container>
          <GridItem md={8} sm={12} xs={12}>
            <InputsLoader variant="rectangular" height={500} startingPoint="middle" />
          </GridItem>
          <Container md={4} sm={12} xs={12}>
            <GridItem xs={12}>
              <StatusLoader variant="rectangular" height={250} startingPoint="end" />
              <ActionsLoader variant="rectangular" height={234} startingPoint="end" />
            </GridItem>
          </Container>
        </Container>
      </Container>
    </MainContainer>
  );
}

const MainContainer = styled(Container)`
  padding: ${({ theme }) => `0  ${theme.padding.xl}`};
`;

const SkeletonLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-bottom: ${({ theme }) => `${theme.margin.m}`};
`;

const InputsLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-right: ${({ theme }) => `${theme.margin.m}`};
  }
`;

const StatusLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    margin-top: ${({ theme }) => `${theme.margin.m}`};
  }
`;

const ActionsLoader = styled(Skeleton)`
  padding: ${({ theme }) => `0 ${theme.padding.l} ${theme.padding.l}`};
  margin-top: ${({ theme }) => `${theme.margin.m}`};
`;
