import { UserPermit } from 'api/resources/models/AutoGenerated';
import { MenuItem } from './types';

export function mapMenuItems(menuItems: MenuItem[], userPermits: UserPermit[]) {
  const codes = userPermits.map((permit) => permit.code);

  return menuItems.filter((item) =>
    item?.permitCode ? codes.includes(item.permitCode) : item
  );
}
