import styled from '@emotion/styled';
import { Container, GridItem } from 'components/Layout';
import { Text } from 'components/Typography';
import { GrayBackdrop, Loader } from 'components/Loader';
import {
    InputContainer,
    MainInputContainer,
    Header,
} from 'components/PageLayout';
import { Checkbox } from 'components/Checkbox';
import { Company } from './form';
import { useAccountProfilesByCompanyId } from 'pages/hooks';

interface Props {
    company: Company
}

export function CompanyAccountProfiles({ company }: Props) {
    const { accountProfiles, isAccountProfilesLoading } = useAccountProfilesByCompanyId({
        id: company.id,
    });
    const isBusy = isAccountProfilesLoading;

    return (
        <MainInputContainer direction="column">
            <GrayBackdrop open={isBusy}>
                <Loader />
            </GrayBackdrop>
            <InputContainer direction="column">
                <Header>Added Account Profiles</Header>
                {accountProfiles?.length > 0 ? accountProfiles?.map((p, key) => (
                    <AddedAcountProfile direction="row" alignItems="center" key={key}>
                        <GridItem md={4} sm={4} xs={2}>
                            <StyledTitle noWrap fontSize="m">
                                {p.name}
                            </StyledTitle>
                        </GridItem>
                        <GridItem md={8} sm={8} xs={10}>
                            <Container direction="row" justify='flex-end' alignItems='center' wrap="wrap">
                                <GridItem lg={2} md={3} sm={3} xs={3}>
                                    <StyledText noWrap fontSize="m">
                                        {p.userCount} users
                                    </StyledText>
                                </GridItem>
                                <GridItem lg={4} md={5} sm={5} xs={5} wrap="wrap">
                                    <Checkbox
                                        checked={p.isTemplate}
                                        label="Is Template"
                                        color="primary"
                                    />
                                </GridItem>
                            </Container>
                        </GridItem>
                    </AddedAcountProfile>
                )) : (
                    <AddedAcountProfile direction="row" alignItems="center">
                        <GridItem md={4} sm={4} xs={2}>
                            <StyledTitle noWrap fontSize="m">
                                No accounts profiles are added.
                            </StyledTitle>
                        </GridItem>
                    </AddedAcountProfile>
                )}
            </InputContainer>
        </MainInputContainer>
    );



}

const AddedAcountProfile = styled(Container)`
  min-height: 40px;
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.main}`};
  box-shadow: ${({ theme }) => theme.shadow.s};
  border-radius: ${({ theme }) => theme.border.radius};
  padding-left: 12px;
  margin-bottom: ${({ theme }) => theme.margin.s};
`;

const StyledText = styled(Text)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 10px;
  }
`;

const StyledTitle = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.disabled};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.xsm}px`}) {
    font-size: 10px;
  }
`;
