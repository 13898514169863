import styled from '@emotion/styled';
import { DateStatus } from './types';

export const IconContainer = styled.div<{ status: DateStatus }>`
  display: flex;
  svg {
    font-size: 24px;
  }
  color: ${(props) =>
    props.status === 'green'
      ? ({ theme }) => `${theme.palette.icon.green}`
      : props.status === 'amber'
      ? ({ theme }) => `${theme.palette.icon.warn}`
      : props.status === 'red'
      ? ({ theme }) => `${theme.palette.icon.red}`
      : []};
`;
