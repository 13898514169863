import { Checkbox } from 'components/Checkbox';
import { Container, GridItem } from 'components/Layout';
import { Controller, useFormContext } from 'react-hook-form';
import { AccountProfileForm, FirstLevelAccountProfilePermit } from './form';
import styled from '@emotion/styled';
import { Subheading } from 'components/Typography';
import { RepairsListsPermits } from 'api/resources/models/AutoGenerated';

export function AccountProfilesRepairsLists() {
  const { getValues, watch, setValue } = useFormContext<AccountProfileForm>();
  const permits = getValues('permits.repairsListsPermits');
  watch('permits.repairsListsPermits.corePermits');

  return (
    <Container direction="column">
      <Permits
        permitsToRender={permits.corePermits}
        keyOfrepairsListsPermits="corePermits"
        onPermitChange={onPermitChange}
      />
      <StyledSubheading>Columns in the Repairs Lists</StyledSubheading>
      <Permits
        permitsToRender={permits.fieldLevelPermits}
        keyOfrepairsListsPermits="fieldLevelPermits"
        isDisabled={!permits.corePermits.some((p) => p.isActive)}
      />
    </Container>
  );

  function onPermitChange() {
    const isAnyCoreActive = permits.corePermits.some((p) => p.isActive);
    if (!isAnyCoreActive)
      permits.fieldLevelPermits.forEach((p, flvIndex) => {
        setValue(
          `permits.repairsListsPermits.fieldLevelPermits.${flvIndex}.isActive`,
          false
        );
      });
  }
}

function Permits({
  permitsToRender,
  keyOfrepairsListsPermits,
  isDisabled,
  onPermitChange,
}: {
  permitsToRender: FirstLevelAccountProfilePermit[];
  keyOfrepairsListsPermits: keyof RepairsListsPermits;
  isDisabled?: boolean;
  onPermitChange?: () => void;
}) {
  const { control, setValue } = useFormContext<AccountProfileForm>();

  return (
    <>
      {permitsToRender.map((permit, index) => (
        <>
          <Controller
            key={permit.accountProfilePermitId}
            render={({ field }) => (
              <Checkbox
                {...field}
                disabled={isDisabled}
                label={`${permit.name} (${permit.permit})`}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                  if (onPermitChange) onPermitChange();
                  permit.children?.forEach((_child, childIndex) =>
                    setValue(
                      `permits.repairsListsPermits.${keyOfrepairsListsPermits}.${index}.children.${childIndex}.isActive`,
                      false
                    )
                  );
                }}
              />
            )}
            name={`permits.repairsListsPermits.${keyOfrepairsListsPermits}.${index}.isActive`}
            control={control}
            defaultValue={false}
          />

          {permit.children?.map((child, childIndex) => (
            <ChildContainer key={child.accountProfilePermitId}>
              <Controller
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    disabled={!permit.isActive}
                    label={`${child.name} (${child.permit})`}
                    checked={!permit.isActive ? false : field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
                name={`permits.repairsListsPermits.${keyOfrepairsListsPermits}.${index}.children.${childIndex}.isActive`}
                control={control}
                defaultValue={false}
              />
            </ChildContainer>
          ))}
        </>
      ))}
    </>
  );
}

const ChildContainer = styled(GridItem)`
  padding-bottom: ${({ theme }) => theme.padding.s};
  margin-left: ${({ theme }) => theme.margin.l};
`;

const StyledSubheading = styled(Subheading)`
  margin-top: ${({ theme }) => theme.margin.m};
  margin-bottom: ${({ theme }) => theme.margin.s};
`;
