import styled from '@emotion/styled';
import { Label } from "components/PageLayout";
import { DatePickerField } from './DatePickerFields';
import { GridItem } from "components/Layout";


export interface InputDateProps {
    onChange: (date: Date | null) => void;
    value: Date | null;
    isDisabled?: boolean;
    minDate?: Date | null;
    maxDate?: Date | null;
    label?: string
}


function StartInputDate({
    value,
    label,
    onChange,
    maxDate
}: InputDateProps) {
    return (
        <InputButton md={3} sm={4} xs={12}>
            <Label>{label}</Label>
            <DatePickerField
                onChange={onChange}
                value={value}
                maxDate={maxDate}
            />
        </InputButton>
    )
}

const InputButton = styled(GridItem)`
    padding-right: ${({ theme }) => theme.margin.s};
    @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
        margin - bottom: 6px;
    }
    & svg {
        width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    }
`;

export default StartInputDate